* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Reem Kufi", sans-serif !important;
}

img {
    max-width: 100%;
    height: auto;
}

.App {
    min-height: 100vh;
    width: 85vw;
    max-width: 1900px;
    margin: 0 auto;
}

body {
    max-width: 100%;
    overflow-x: hidden;
    background-color: #f6f6f6;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
}

.navbar-menu-container {
    display: none;
}

@media (min-width: 792.98px) {
    .nav {
        position: fixed;
        top: 0;
        z-index: 10;
        width: 85vw;
        background-color: #56ff13a3;
        margin-top: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
    }
}

.nav-item {
    color: white;
    padding: 15px;
}

.nav-item:hover {
    background-color: #ffffff;
    border-radius: 30px;
}

.navbar-links-container a {
    margin-right: 3rem;
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
    font-weight: 600;
}

.primary-button {
    padding: 0.9rem 1.75rem;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
}

.navbar-cart-icon {
    font-size: 1.15rem;
}

.primary-button:hover {
    background-color: rgb(234, 234, 234);
}

.navbar-menu-container svg {
    font-size: 1.5rem;
    cursor: pointer;
}

.home-banner-container {
    position: relative;
    display: flex;
    padding-top: 3rem;
}

.home-bannerImage-container {
    position: absolute;
    top: -100px;
    right: -170px;
    z-index: -2;
    max-width: 700px;
}

.home-image-section {
    max-width: 600px;
    flex: 1;
}

.home-text-section {
    margin-top: 12vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.primary-heading {
    font-size: clamp(2rem, 5vw, 4rem);
    color: #4c4c4c;
    /* line-height: 5rem; */
    max-width: 600px;
}

.primary-text {
    font-size: clamp(1rem, 3vw, 1.5rem);
    max-width: 500px;
    color: #6a6a6a;
    margin: 1.5rem 0;
}

.secondary-text {
    font-size: 1.2rem;
    font-weight: 400;
    color: #505050;
    margin: 1rem 0;
}

.secondary-button {
    padding: 1rem 2.5rem;
    background-color: #3ea11c;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: white;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secondary-button svg {
    margin-left: 0.75rem;
    font-size: 1.5rem;
}

.secondary-button:hover {
    background-color: #317e16;
}

.about-section-container {
    margin-top: 20rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-background-image-container {
    position: absolute;
    left: -150px;
    z-index: -2;
}

.about-section-image-container {
    flex: 0.9;
    margin-right: 1rem;
}

.about-section-text-container {
    flex: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.primary-subheading {
    font-weight: 700;
    color: #62ff2d;
    font-size: 1.15rem;
}

.about-buttons-container {
    margin-top: 2rem;
    display: flex;
}

.watch-video-button {
    margin-left: 2rem;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    color: #484848;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.watch-video-button svg {
    font-size: 3rem;
    margin-right: 1rem;
}

.contact-page-wrapper h1 {
    max-width: 900px !important;
}

.contact-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 6rem 0;
}

.contact-form-container {
    background-color: white;
    max-width: 700px;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 5rem;
}

.contact-form-container input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
}

.footer-wrapper {
    display: flex;
    margin: 10rem 0 6rem;
}

.footer-logo-container {
    max-width: 110px;
}

.footer-icons {
    margin-top: 2.5rem;
}

.footer-icons svg {
    font-size: 1.5rem;
    margin-right: 1.25rem;
    color: #585858;
}

.footer-section-two {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.footer-section-columns {
    display: flex;
    flex-direction: column;
    min-width: 190px;
}

.footer-section-columns span {
    margin: 0.25rem 0;
    font-weight: 600;
    color: #4c4c4c;
    cursor: pointer;
}

.work-section-wrapper {
    margin-top: 15rem;
}

.work-section-top p {
    text-align: center;
    max-width: 600px !important;
}

.work-section-top h1 {
    max-width: 700px !important;
}

.work-section-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.work-section-info {
    width: 290px;
    min-height: 350px;
    background-color: white;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 1rem;
    color: #e1e1e1;
    margin: 1rem 2rem;
}

.work-section-info h2 {
    margin: 1rem 0;
}

.work-section-bottom {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.work-section-info p {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.scroll-to-top-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
}

.scroll-to-top-button.visible {
    display: flex;
}

@media (max-width: 1000px) {
    .navbar-links-container a {
        margin-right: 1rem;
        font-size: 1rem;
    }

    .primary-button {
        font-size: 1rem;
    }

    .home-bannerImage-container {
        max-width: 600px;
    }
}

@media (max-width: 800px) {
    .nav-logo-container {
        max-width: 140px;
    }

    .navbar-links-container {
        display: none;
    }

    .navbar-menu-container {
        display: flex;
    }

    .home-bannerImage-container {
        max-width: 450px;
    }

    .home-banner-container,
    .about-section-container {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .about-section-container {
        flex-direction: column;
    }

    .home-image-section,
    .about-section-image-container {
        width: 100%;
        max-width: 400px;
    }

    .primary-heading {
        text-align: center;
        max-width: 90%;
    }

    .primary-text {
        text-align: center;
        max-width: 80%;
    }

    .home-text-section,
    .about-section-text-container {
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
    }

    .secondary-button {
        font-size: 1rem;
        padding: 0.8rem 2rem;
        cursor: pointer;
    }

    .about-section-container {
        margin-top: 5rem !important;
    }

    .about-buttons-container {
        justify-content: center;
        flex-direction: column;
    }

    .primary-subheading {
        text-align: center;
    }

    .watch-video-button {
        margin-left: 0 !important;
        margin-top: 1rem;
        font-size: 1rem !important;
    }

    .watch-video-button svg {
        margin-right: 0.5rem !important;
    }

    .about-section-image-container {
        margin-right: 0 !important;
    }

    .work-section-wrapper {
        margin-top: 5rem !important;
    }

    .work-section-bottom {
        margin-top: 1rem !important;
    }

    .contact-form-container {
        padding: 0.5rem !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 1rem !important;
    }

    .contact-form-container input {
        font-size: 1rem !important;
        max-width: 100%;
        padding: 0.5rem 1rem !important;
        margin-bottom: 0.8rem !important;
        text-align: center;
    }

    .footer-wrapper {
        flex-direction: column;
    }

    .footer-section-two {
        flex-direction: column;
        margin-top: 2rem;
    }

    .footer-section-columns {
        margin: 1rem 0;
    }

    .App {
        max-width: 95vw;
    }
}

@media (max-width: 793px) {
    .home-bannerImage-container,
    .about-background-image-container {
        display: none;
    }
}

.carousel-page-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
}
.carousel-page-wrapper {
    margin: 10vh 0 15vh 0;
}
.carousel-container {
    position: relative;
    margin: 5vh 0;
    overflow: hidden;
    padding: 0 20px;
}
@media (min-width: 768px) {
    .carousel-page-wrapper {
        max-width: 70%;
        align-self: center;
        margin: 10vh auto;
    }
}
.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
    touch-action: none;
}
.carousel-image {
    width: 400px;
    height: 400px;
    object-fit: cover;
    display: block;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    border: 5px solid #3ea11c;
}
.carousel-control {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    background-color: rgba(76, 76, 76, 0.8);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.carousel-control.prev {
    left: 0px;
}
.carousel-control.next {
    right: 0px;
}
@media (max-width: 768px) {
    .carousel-control.prev {
        left: -0.5px;
    }
    .carousel-control.next {
        right: -0.5px;
    }
}
.carousel-thumbnails {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 7vh;
}

.thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
    border: 3px solid rgb(144 252 88);
    opacity: 0.6;
    transition: opacity 0.3s;
}
.thumbnail:hover,
.thumbnail.active {
    opacity: 1;
}
